import { recomputeStyle } from './internal/animation';
import amdify from './internal/amdify';
import globalize from './internal/globalize';
import * as deprecate from './internal/deprecation';

const ID_BANNER_CONTAINER = 'header';

const getContainer = () => document.getElementById(ID_BANNER_CONTAINER);

function banner(options) {
    const bannerContainer = getContainer();
    if (!bannerContainer) {
        throw new Error(
            'Unable to find the application header. Please ensure that an element with the ID "header" is present in the DOM'
        );
    }
    const bannerElement = createBannerElement(options);

    renderBannerElement(bannerElement, bannerContainer);
    removeHiddenBanners(bannerContainer);
    return bannerElement;
}

function createBannerElement({ body = '', type = 'error' }) {
    const banner = document.createElement('div');
    banner.className = `aui-banner aui-banner-${type}`;
    banner.setAttribute('role', 'alert');
    banner.innerHTML = body;
    
    const screenReaderInfo = document.createElement('div');
    screenReaderInfo.innerText = `${type}`;
    screenReaderInfo.classList.add('assistive')
    banner.prepend(screenReaderInfo);

    return banner;
}

function removeHiddenBanners(bannerContainer) {
    const hiddenBanners = bannerContainer.querySelectorAll('.aui-banner[hidden]');
    const lazyDeprecate = deprecate.getMessageLogger('Banner [hidden] attribute', {
        removeInVersion: '10.0.0',
        sinceVersion: '9.8.0',
        deprecationType: 'ATTRIBUTE',
        extraInfo: 'The hidden attribute on the banner is no longer effective. Future banner function calls won\'t remove banners with this attribute.'
    });

    hiddenBanners.forEach(function (banner) {
        lazyDeprecate();
        banner.remove();
    });
}

function renderBannerElement(bannerElement, bannerContainer) {
    bannerContainer.prepend(bannerElement);
    recomputeStyle(bannerElement);
}

amdify('aui/banner', banner);
globalize('banner', banner);
export default banner;